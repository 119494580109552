<div class="wrapper">

  <app-header></app-header>

  <div #contentWrapper class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
