import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../utils';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {SurveyAnswers, SurveyData} from '../../models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  public surveryData: SurveyData;
  public loading = true;
  public isCompleted = false;

  public submitting: Boolean = false;

  private id: string;
  private verification: string;

  public surveyAnswers: SurveyAnswers;
  public surveyOptions: any[];

  public isError = false;

  constructor(private apiService: ApiService,
              private toast: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.surveryData = new SurveyData();
    this.surveyAnswers = new SurveyAnswers();

    this.route.queryParams
      .subscribe(params => {
          this.id = params.id;
          this.verification = params.verification;
          if (this.id && this.verification) {
            this.getMyData();
          } else {
            console.log('Redirect to 404');
            this.router.navigate(['/404']);
          }
        }
      );

    this.surveyOptions = [
      {name: 'Very Poor', value: 1},
      {name: 'Poor', value: 2},
      {name: 'Neutral', value: 3},
      {name: 'Good', value: 4},
      {name: 'Very Good', value: 5},
    ];
  }

  public getMyData() {
    this.apiService.getSurveyData(this.id, this.verification).subscribe(
      oResponse => {
        this.surveryData = oResponse.data;
        this.processData();
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.isError = true;
      }
    );
  }

  public submit() {
      this.submitting = true;
      this.apiService.postSurvey(this.id, this.verification, this.surveyAnswers).subscribe(
        data => {
          const vMessage = this.translate.instant('survey.toastSuccess');

          this.toast.success(vMessage);
          this.isCompleted = true;
          this.submitting = false;
        },
        error => {
          const vMessage = this.translate.instant('survey.toastError');
          this.toast.error(vMessage);
          this.submitting = false;
        }
      );
  }

  public getTitle(): string {
    let vTitle = this.surveryData.company;
    if (this.surveryData.project) {
      vTitle = this.surveryData.project;
    }

    vTitle += ' - ' + this.surveryData.year + '/' + this.surveryData.kwartaal;
    return vTitle;
  }

  private processData() {
    if (this.surveryData.isCompleted) {
      this.isCompleted = true;
    }
  }

  private verifyData(): boolean {
    const lErrors = [];

    if (!Number.isInteger(this.surveyAnswers.Q1)) {
      lErrors.push(this.translate.instant('survey.missingq1'));
    }

    if (!Number.isInteger(this.surveyAnswers.Q3)) {
      lErrors.push(this.translate.instant('survey.missingq3'));
    }

    if (!Number.isInteger(this.surveyAnswers.Q4)) {
      lErrors.push(this.translate.instant('survey.missingq4'));
    }

    if (!Number.isInteger(this.surveyAnswers.Q5)) {
      lErrors.push(this.translate.instant('survey.missingq5'));
    }

    if (!Number.isInteger(this.surveyAnswers.Q6)) {
      lErrors.push(this.translate.instant('survey.missingq6'));
    }

    return lErrors.length === 0;
  }

  private isNullOrWhitespace(pInput): boolean {
    if (typeof pInput === 'undefined' || pInput == null) {
      return true;
    }

    return pInput.trim().length < 1;
  }
}
