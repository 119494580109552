import {PortalKey} from './portal-key';

// https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/VALIDATION.md
export class PortalKeys {
  static MENU: PortalKey = new PortalKey('Menu', null);
  static AUTH: PortalKey = new PortalKey('Auth', null);
  static TOKEN_CONTENT: PortalKey = new PortalKey('TokenContent', null);

  static DASHBOARD(pDashboardId: number): PortalKey {
    const vKey = 'DASHBOARD_' + pDashboardId;
    return new PortalKey(vKey, null);
  }
}

// TODO schema, could not get $ref to work

/*
    type: 'object',
    properties: {
      Name: {'type': 'string'},
      RouterLink: {'type': 'string'},
      Icon: {'type': 'string'},
      Children: {'$ref': '#/definitions/menu'}
    },
    definitions: {
      menu: {
        type: 'object',
        properties: {
          value: {'type': 'string'},
          branches: {
            type: 'array',
            items: {'$ref': '#/definitions/tree'},
            minItems: 1
          }
        },
        'required': ['value']
      }
    }
  });
 */
