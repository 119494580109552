import {Priceline} from '../response/price-line';
import {GolazoData} from '../response/golazo-data';

export class ProcessRequest {
  public itemId: number;
  public firma: string;
  public straatNr: string;
  public zip_Plaats: string;
  public btw: string;
  public contact: string;
  public title: string;
  public mail: string;
  public telefoon: string;
  public gsm: string;

  public pricelines: Priceline[];

  constructor(pData: GolazoData) {
    this.itemId = pData.itemId;
    this.firma = pData.firma || '';
    this.straatNr = pData.straatNr || '';
    this.zip_Plaats = pData.zip_Plaats || '';
    this.btw = pData.btw || '';
    this.contact = pData.contact || '';
    this.title = pData.title || '';
    this.mail = pData.mail || '';
    this.telefoon = pData.telefoon || '';
    this.gsm = pData.gsm || '';

    this.pricelines = pData.project.pricelines;
  }
}
