<div class="content-header">
  <div class="container">
    <div class="row mb-2">
      <div class="col-sm-12" *ngIf="!loading">
        <h1 class="m-0" style="text-align: center;"> Afsprakenmaker - Survey
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container" *ngIf="isError">
    <div class="row">
      <div class="col-lg-12">
        <h1>{{'survey.cannotload' | translate }}</h1>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="isCompleted">
    <div class="col-lg-12">
      <div class="card card-red card-outline">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title m-0">{{getTitle()}}</h5>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="form-group row">
            <h1>{{'survey.alreadydone' | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="loading">
    <div class="row">
      <div class="col-lg-12">
        <p-progressSpinner strokeWidth="5"
                           animationDuration="1s"></p-progressSpinner>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!loading && !isError && !isCompleted">
    <div class="col-lg-12">
      <div class="card card-red card-outline">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title m-0">{{getTitle()}}</h5>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="form-group row">
            <label for="Q1" class="col-sm-5 col-form-label">On a scale of 0 to 10, where 0 is not at all likely and 10
              is extremely likely, how likely are you to recommend our services to others?</label>
            <div class="col-sm-7" style="margin-top: 20px;">
              <p-rating [(ngModel)]="surveyAnswers.Q1" [cancel]="false"
                        stars="10"
                        id="Q1"
                        [iconOnStyle]="{'color':'#dc3545'}"
              ></p-rating>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q2" class="col-sm-5 col-form-label">May we know the reason for the score you gave in question
              1?</label>
            <div class="col-sm-7">
              <textarea pInputTextarea id="Q2" rows="4" cols="54" [(ngModel)]='surveyAnswers.Q2'></textarea>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q3" class="col-sm-5 col-form-label">How satisfied are you with the service we provided?</label>
            <div class="col-sm-7">
              <p-selectButton id="Q3" [options]="surveyOptions" [(ngModel)]="surveyAnswers.Q3" optionLabel="name"
                              optionValue="value">
              </p-selectButton>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q4" class="col-sm-5 col-form-label">How satisfied are you with the software solution we
              delivered?</label>
            <div class="col-sm-7">
              <p-selectButton id="Q4" [options]="surveyOptions" [(ngModel)]="surveyAnswers.Q4" optionLabel="name"
                              optionValue="value"></p-selectButton>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q5" class="col-sm-5 col-form-label">How likely are you to continue your subscription or contract
              with us?</label>
            <div class="col-sm-7">
              <p-selectButton id="Q5" [options]="surveyOptions" [(ngModel)]="surveyAnswers.Q5" optionLabel="name"
                              optionValue="value"></p-selectButton>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q6" class="col-sm-5 col-form-label">How would you rate the overall value of our software
              services?</label>
            <div class="col-sm-7">
              <p-selectButton id="Q6" [options]="surveyOptions" [(ngModel)]="surveyAnswers.Q6" optionLabel="name"
                              optionValue="value"></p-selectButton>
            </div>
          </div>

          <div class="form-group row">
            <label for="Q7" class="col-sm-5 col-form-label">Would you like to leave any additional feedback or comments
              about our Software services or SDR team?</label>
            <div class="col-sm-7">
              <textarea pInputTextarea id="Q7" rows="4" cols="54" [(ngModel)]="surveyAnswers.Q7"></textarea>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-6">
              <button type="submit" class="btn btn-am" (click)="submit()" [disabled]="submitting" *ngIf="verifyData()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <i class="pi pi-ellipsis-v" style="color:#f4f6f9"></i>
      <i class="p-rating-icon pi pi-star-o" style="color:#f4f6f9"></i>
      <i class="pi pi-star" style="color:#f4f6f9"></i>
    </div>
  </div>
</div>
