import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './pages/main/main.component';
import {BlankComponent} from './views/blank/blank.component';
import {NotFoundComponent} from './views/not-found/not-found.component';
import {HomeComponent} from './views/home/home.component';
import {SurveyComponent} from './views/survey/survey.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'blank',
        component: BlankComponent,
      },
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path: 'survey',
        component: SurveyComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
