import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../utils';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {GolazoData, Priceline, ProcessRequest} from '../../models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public data: GolazoData;
  public loading = true;
  public totals = {
    total: 0.00
  };

  public submitting: Boolean = false;
  public hasDocusign: Boolean = false;

  private id: string;
  private verification: string;

  constructor(private apiService: ApiService,
              private toast: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.data = new GolazoData();
    this.route.queryParams
      .subscribe(params => {
          this.id = params.id;
          this.verification = params.verification;
          if (this.id && this.verification) {
           // this.getMyData();
          } else {
            console.log('Redirect to 404');
            this.router.navigate(['/404']);
          }
        }
      );
  }

  public getMyData() {
    // this.data = this.apiService.getData(this.id, this.verification).pipe(map(oResponse => oResponse.data));
    this.apiService.getData(this.id, this.verification).subscribe(
      oResponse => {
        this.data = oResponse.data;
        this.processData();
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  private processData() {
    if (this.data.docusign) {
      if (this.data.docusign.length > 0) {
        this.hasDocusign = true;
      }
    }
    if (this.data.title) {
      this.data.title = this.data.title.replace(/<\/?[^>]+(>|$)/g, '');
    }
    if (this.data.btw) {
      this.data.btw = this.data.btw.replace(/<\/?[^>]+(>|$)/g, '');
    }
    this.data.project.pricelines.forEach(x => {
      x.amount = 0;
    });
  }

  public formatPrice(pNumber: number) {
    if (!pNumber) {
      pNumber = 0;
    }
    return parseFloat(pNumber.toFixed(2));
  }

  public changePriceFormat(pNumber: any) {
    if (pNumber) {
      const vParsed = parseFloat(pNumber.toString()).toFixed(2);
      return vParsed.toString().replace('.', ',');
    } else {
      return '0';
    }
  }

  public calculateTotal(pData: Priceline) {
    pData.total = pData.amount * parseFloat(parseFloat(pData.price).toFixed(2));
    this.recalculateTotals();
  }

  public submit() {

    const oData = new ProcessRequest(this.data);

    const vHasSelected = this.verifyData(oData);

    if (vHasSelected) {
      this.submitting = true;
      this.apiService.postData(this.id, this.verification, oData).subscribe(
        data => {
          const vMessage = this.translate.instant('home.toastSuccess', {
            mail: this.data.mail
          });

          this.toast.success(vMessage);
          this.hasDocusign = true;
          this.submitting = false;
        },
        error => {
          const vMessage = this.translate.instant('home.toastError');
          this.toast.error(vMessage);
          this.submitting = false;
        }
      );
    }
  }



  private formatDate(pDate: Date) {
    const vTemp = new Date(pDate);
    const vMonth = (vTemp.getMonth() + 1).toString().padStart(2, '0');
    const vDay = vTemp.getDate().toString().padStart(2, '0');
    const vYear = vTemp.getFullYear();

    return [vYear, vMonth, vDay].join('-');
  }

  private verifyData(pData: ProcessRequest): boolean {
    const lCheck = [];
    const lErrors = [];

    pData.pricelines.forEach(x => {
      if (x.amount === 0) {
        lCheck.push(false);
      } else {
        lCheck.push(true);
      }
    });
    let vVerify = lCheck.includes(true);
    if (!vVerify) {
      lErrors.push(this.translate.instant('home.toastInvalid'));
    }

    if (pData.btw === '' || pData.firma === '' || pData.zip_Plaats === '' || pData.straatNr === '') {
      lErrors.push(this.translate.instant('home.formInvalid'));
      vVerify = false;
    }
    lErrors.forEach(x => {
      this.toast.error(x);
    });
    return vVerify;
  }

  public recalculateTotals() {
    this.totals.total = this.formatPrice(this.data.project.pricelines.map(item => {
      if (item.total) {
        return item.total;
      }
      return 0;
    }).reduce((prev, next) => prev + next));
  }
}
