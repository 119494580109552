<nav class="main-header navbar navbar-expand-md navbar-custom-color navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item d-none d-sm-inline-block">
      <a href="https://afsprakenmaker.be/">
        <img src="https://afsprakenmaker.be/swfiles/files/logo_am-mob.png?nc=1645104798" alt="Logo" width="200" height="50"
             style="border:0; outline:none; text-decoration:none;">
      </a>
<!--
      <select #langSelect (change)="translate.use(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
      </select>
      -->
    </li>
  </ul>
</nav>
