<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="error-page">
          <h2 class="headline text-warning"> 404</h2>

          <div class="error-content">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i>{{'notfound.message1' | translate }}</h3>

            <p>
              {{'notfound.message2' | translate }}
            </p>
          </div>
          <!-- /.error-content -->
        </div>
      </div>
    </div>
  </div>
</div>

