export class PortalCalls {

  static DATAURL = 'api/data';
  static SURVEYURL = 'api/survey';

  static DATA(pId: string, pVerification: string) {
    let vUrl = PortalCalls.DATAURL;

    vUrl += '?id=' + pId;
    vUrl += '&verification=' + pVerification;

    return vUrl;
  }

  static SURVEYDATA(pId: string, pVerification: string) {
    let vUrl = PortalCalls.SURVEYURL;

    vUrl += '?id=' + pId;
    vUrl += '&verification=' + pVerification;

    return vUrl;
  }
}
