import {Injectable} from '@angular/core';
import {PortalKey} from '../../models';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {StorageMap} from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  constructor(private router: Router,
              private toast: ToastrService,
              private storage: StorageMap) {
  }

  // https://github.com/cyrilletuzi/angular-async-local-storage

  public clear() {
    return this.storage.clear();
  }

  public setData(pKey: PortalKey, pData: any) {
    return this.storage.set(pKey.Key, pData);
  }

  /**
   * getData<T>(PortalKey).subscribe((data) => {   data; // undefined });
   * @param pKey use the PortalKeys model
   */
  public getData<T>(pKey: PortalKey) {
    return this.storage.get<T>(pKey.Key, pKey.Schema);
  }

  /**
   * getData<T>(PortalKey).watch((data) => {   data; // undefined });
   * @param pKey use the PortalKeys model
   */
  public watchData<T>(pKey: PortalKey) {
    return this.storage.watch<T>(pKey.Key, pKey.Schema);
  }
}
