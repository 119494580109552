<div class="content-header">
  <div class="container">
    <div class="row mb-2">
      <div class="col-sm-12" *ngIf="!loading">
        <h1 class="m-0" style="text-align: center;"> Afspraken Maker
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container" *ngIf="!loading">

    <div>
      <i class="pi pi-ellipsis-v" style="color:#f4f6f9"></i>
    </div>
  </div>
</div>
