import {Component, OnInit} from '@angular/core';
import {StorageService} from './utils';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Portal';

  constructor(private storageService: StorageService,
              private router: Router,
              private toast: ToastrService,
              private translate: TranslateService) {
    translate.addLangs(['nl', 'en', 'fr', 'de']);
    translate.setDefaultLang('nl');
    translate.use('nl');
  }

  ngOnInit(): void {
    /*
    this.storageService.getData<TokenContent>(PortalKeys.TOKEN_CONTENT).subscribe(x => {
      if (x === undefined) {
        this.goToLogin('You are not logged in, redirecting to the login page');
      } else {
        if (x.exp < Date.now() / 1000) {
          this.goToLogin('Your token has expired, redirecting to the login page');
        }
      }
    });*/
  }

  private goToLogin(pMessage: string) {
    this.toast.error(pMessage);
    this.router.navigate(['login']);
  }
}
