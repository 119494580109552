export class SurveyAnswers {
  Q1: number;
  Q2: string;
  Q3: number;
  Q4: number;
  Q5: number;
  Q6: number;
  Q7: string;

  public SurveyAnswers() {
    this.Q1 = 0;
    this.Q3 = 0;
    this.Q4 = 0;
    this.Q5 = 0;
    this.Q6 = 0;
  }
}
