export class PodioTask {
  id: number;
  taskId: number;
  status: string;
  podioGroup: string;
  text: string;
  description: string;
  private: boolean | null;
  due_on: string;
  due_date: string;
  due_time: string;
  isLiked: boolean;
  likeCount: number;
  pinned: boolean;
  spaceId: number | null;
  podioLink: string;
  createdOn: string | null;
  completedOn: string | null;
  externalId: string;
  ref_type: string;
  ref_id: number;
  created_by: string;
  completedBy: string;
  responsible_UserId: number | null;
  responsible_ProfileId: number | null;
  responsible_Type: string;
  recurrence_Name: string;
  recurrence_Step: number | null;
  recurrence_Until: string | null;
  recurrence_Days: string;
  recurrence_RepeatOn: string;
}
