import {JSONSchema} from '@ngx-pwa/local-storage';

export class PortalKey {
  public Key: string;
  public Schema: JSONSchema;

  constructor(pKey: string, pSchema: JSONSchema) {
    this.Key = pKey;
    this.Schema = pSchema;
  }
}
