<footer class="main-footer">
  <div class="float-right d-none d-sm-block">
    <b>Version</b> {{ appVersion }}
  </div>
  <strong>Copyright &copy; {{year}}</strong>
  All rights reserved.
</footer>

<!--
<div class="float-right d-none d-sm-inline">
    Anything you want
  </div>
  -->
