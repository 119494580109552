import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public appVersion =  environment.appVersion;
  public year = 2020;

  constructor() {
  }

  ngOnInit() {
    const oToday = new Date();
    this.year = oToday.getFullYear();
  }
}
