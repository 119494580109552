export class BasePodioItem {
  id: number;
  itemId: number;
  appId: number | null;
  createdOn: string | null;
  createdBy: string;
  lastEventOn: string | null;
  podioLink: string;
  deleted: boolean;
}
