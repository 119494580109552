import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {ApiService} from '../../../utils';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: UntypedFormGroup;
  public isLoading = false;

  constructor(private apiService: ApiService, private router: Router, public translate: TranslateService) {
  }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null),
    });
  }

  public doSearch() {
    if (this.searchForm.valid) {
      this.router.navigate(['search', this.searchForm.controls.search.value]);
    }
  }
}
