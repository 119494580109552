import {Project} from './project';

export class GolazoData {
  itemId: number;
  firma: string;
  straatNr: string;
  zip_Plaats: string;
  btw: string;
  contact: string;
  title: string;
  mail: string;
  telefoon: string;
  gsm: string;
  project: Project;
  docusign: string;
}
