import {Priceline} from './price-line';

export class Project {
  eventName: string;
  eventDateStart: Date;
  eventDateEnd: Date;
  pricelines: Priceline[];
  languages: string[];
  responsible: string;
  responsibleNr: string;
}
