import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {DefaultResponseModel, GolazoData, PortalCalls, ProcessRequest, ResponseModel, SurveyAnswers, SurveyData} from '../../models';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient,
              @Inject('BASE_URL') private baseUrl: string,
              private toast: ToastrService,
              private translate: TranslateService) {
  }

  public getData(pId: string, pVerification: string) {
    const vUrl = this.baseUrl + PortalCalls.DATA(pId, pVerification);
    return this.doInternalGet<ResponseModel<GolazoData>>(vUrl);
  }

  public getSurveyData(pId: string, pVerification: string) {
    const vUrl = this.baseUrl + PortalCalls.SURVEYDATA(pId, pVerification);
    return this.doInternalGet<ResponseModel<SurveyData>>(vUrl);
  }

  public postSurvey(pId: string, pVerification: string, pData: SurveyAnswers) {
    const vUrl = this.baseUrl + PortalCalls.SURVEYDATA(pId, pVerification);
    return this.doInternalPost<SurveyAnswers, SurveyData>(vUrl, pData);
  }

  public postData(pId: string, pVerification: string, pData: ProcessRequest) {
    const vUrl = this.baseUrl + PortalCalls.DATA(pId, pVerification);
    return this.doInternalPost<ProcessRequest, DefaultResponseModel>(vUrl, pData);
  }

  //
  // internal functions
  //
  private doInternalGet<T>(pUrl: string): Observable<T> {
    console.log('doing GET on ' + pUrl);
    return this.http.get<T>(pUrl)
      .pipe(
        catchError(err => {
          return this.handleError(err, this.toast);
        })
      );
  }

  private doInternalPost<T, R>(pUrl: string, pPayload: T): Observable<R> {
    console.log('doing POST on ' + pUrl);
    return this.http.post<R>(pUrl, pPayload)
      .pipe(
        catchError(err => {
          return this.handleError(err, this.toast);
        })
      );
  }

  private doInternalDelete<T>(pUrl: string): Observable<T> {
    console.log('doing Delete on ' + pUrl);
    return this.http.delete<T>(pUrl)
      .pipe(
        catchError(err => {
          return this.handleError(err, this.toast);
        })
      );
  }

  private doInternalPut<T, R>(pUrl: string, pPayload: T): Observable<R> {
    console.log('doing Put on ' + pUrl);
    return this.http.put<R>(pUrl, pPayload)
      .pipe(
        catchError(err => {
          return this.handleError(err, this.toast);
        })
      );
  }

  private handleError(error: HttpErrorResponse, pToast: ToastrService) {
    const oReturnData = {error: this.translate.instant('backend.generalerror'), message: ''};
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      // this.toast.error('A client-side or network error occurred');
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was:  ${JSON.stringify(error.error)}`);
      if (error.error) {
        oReturnData.message = error.error.Message;
        let vMessage = error.error.Message;
        if (vMessage.startsWith('backend.')) {
          vMessage = this.translate.instant(vMessage);
        }
        pToast.error(vMessage, this.translate.instant('backend.frombackend'));
      }
    }
    // Return an observable with a user-facing error message.
    return throwError(oReturnData);
  }
}
